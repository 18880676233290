.home-hero {
  color: $colorTerciario;
  background: linear-gradient(to right,
      rgba($colorCuaternario, 0.5),
      rgba($colorCuaternario, 0.7)),
    url(../../assets/svg/common-bg.svg);
  background-position: center;

  height: 100vh;

  /* min-height: 80rem;
  max-height: 120rem; */
  position: relative;

  @include respond(phone) {
    height: unset;
    min-height: unset;
  }

  &__socials {
    position: absolute;
    top: 50%;
    left: 0.5%;
    background-color: rgba($colorSecundario, 0.5);
    border-radius: 30px;
    transform: translateY(-50%);

    @include respond(tab-port) {
      display: none;
    }
  }

  &__mouse-scroll-cont {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);

    @include respond(phone) {
      display: none;
    }
  }

  &__social {
    width: 6rem;
  }

  &__social-icon-link {
    width: 100%;
    display: block;
    padding: 1.2rem;
    transition: background 1s;
    text-align: center;
    border-radius: 30px;

    &:hover {
      background: rgba($colorTerciario, 0.7);
    }

    &--bd-none {
      border-bottom: 0;
    }

    &.cv__logo {
      color: $colorPrimario;
      font-size: 2.4rem;
      font-weight: 800;
      font-family: 'Arial Narrow Bold', sans-serif;
      text-align: center;
      align-items: center;

      &__footer {
        padding: 1px;
        width: fit-content;
        color: white;
        display: inline-block;
        font-size: 2rem;
        background-color: $colorPrimario;
      }
    }
  }

  &__social-icon {
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90rem;
    width: 92%;

    @include respond(phone) {
      padding: 19rem 0 13rem 0;
      margin: auto;
      position: static;
      transform: translate(0, 0);
    }
  }

  &__info {
    margin: 3rem auto 0 auto;
    max-width: 80rem;
  }

  &__cta {
    margin-top: 5rem;
    text-align: center;
  }
}

.about {
  background: $colorTerciario;

  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 8rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-gap: 8rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2.8rem;
      margin-bottom: 3rem;

      @include respond(phone) {
        font-size: 2.4rem;
      }
    }

    &-details-para {
      font-size: 1.8rem;
      color: $colorPrimario;
      max-width: 92%;
      line-height: 1.7;
      margin-bottom: 1rem;

      &--hl {
        font-weight: 700;
        margin: 0 3px;
      }

      &:last-child {
        margin-bottom: 4rem;
      }
    }
  }
}

.projects {
  background-color: rgba($colorSecundario, 0);

  &__row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 5rem;
    margin-bottom: 11rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      margin-bottom: 8rem;
    }

    @include respond(tab-port) {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-img-cont {
      overflow: hidden;
    }

    &-img {
      width: 100%;
      display: block;
      object-fit: cover;
    }

    &-content {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      @include respond(tab-port) {
        align-items: center;
      }

      &-title {
        font-weight: 700;
        font-size: 2.8rem;
        margin-bottom: 2rem;

        @include respond(phone) {
          font-size: 2.4rem;
        }
      }

      &-desc {
        font-size: 1.8rem;
        color: $colorPrimario;
        max-width: 60rem;
        line-height: 1.7;
        margin-bottom: 3rem;

        @include respond(phone) {
          font-size: 1.7rem;
        }
      }

      &-links {
        width: 100%;
        display: flex;
        gap: 2rem;
        justify-content: space-around;
      }
    }
  }
}

.contact {
  background: linear-gradient(to right,
      rgba($colorCuaternario, 0.5),
      rgba($colorCuaternario, 0.7)),
    url(../../assets/svg/common-bg.svg);
  background-size: cover;
  background-position: center;
  color: $colorPrimario;

  &__form-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: rgba($colorTerciario, 2);
    padding: 4rem;
    margin-top: 6rem;
    max-width: 80rem;
    text-align: right;
    width: 95%;
    border-radius: $commonBorderRadius;

    margin: 5rem auto 0 auto;

    @include respond(phone) {
      padding: 3rem;
    }
  }

  &__form-field {
    margin-bottom: 4rem;

    @include respond(phone) {
      margin-bottom: 3rem;
    }
  }

  &__form-label {
    color: $colorPrimario;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
  }

  &__form-input {
    color: $colorPrimario;
    font-weight: 400;
    padding: 2rem;
    width: 100%;
    border: 1px solid rgb(235, 235, 235);
    font-size: 1.6rem;
    letter-spacing: 0px;
    background: linear-gradient(to left,
        rgba($colorPrimario, 0.1),
        rgba($colorPrimario, 0.2));
    /* background: rgb($colorPrimario, 0.5); */
    border-radius: $commonBorderRadius;
    font-weight: 600;

    /* Code for Modern Browsers */
    &::placeholder {
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }

    /* Code for WebKit, Blink, Edge */
    &::-webkit-input-placeholder {
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }

    /* Code for Internet Explorer 10-11 */
    &:-ms-input-placeholder {
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }

    /* Code for Microsoft Edge */
    &::-ms-input-placeholder {
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }

    /* Code for Mozilla Firefox 4 to 18 */
    &:-moz-placeholder {
      opacity: 1;
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }

    /* Code for Mozilla Firefox 19+ */
    &::-moz-placeholder {
      opacity: 1;
      color: #999;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  &__btn {
    width: 30%;

    padding: 2rem 4rem;
    font-size: 1.6rem;

    @include respond(phone) {
      width: 100%;
    }
  }
}