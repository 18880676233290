.project-cs-hero {
  color: $colorTerciario;
  background: linear-gradient(
      to right,
      rgba($colorCuaternario, 0.5),
      rgba($colorCuaternario, 0.7)
    ),
    url(../../assets/svg/common-bg.svg);
  background-size: cover;
  background-position: center;
  position: relative;

  @include respond(phone) {
    height: unset;
    min-height: unset;
  }

  &__content {
    padding: 25rem 0 17rem 0;
    max-width: 90rem;
    width: 92%;
    margin: auto;

    @include respond(phone) {
      padding: 19rem 0 13rem 0;
      margin: auto;
      position: static;
      transform: translate(0, 0);
    }
  }

  &__info {
    margin: 3rem auto 0 auto;
    max-width: 80rem;
  }
  &__cta {
    margin-top: 5rem;
    text-align: center;
  }
}

.project-details {
  &__content {
    padding: 8rem 0;
    max-width: 90rem;
    margin: auto;

    &-title {
      font-weight: 700;
      font-size: 2.8rem;
      margin-bottom: 3rem;
      @include respond(phone) {
        font-size: 2.4rem;
      }
    }
  }

  &__showcase-img-cont {
    width: 100%;
    margin-bottom: 6rem;
  }

  &__showcase-img {
    width: 100%;
  }
  &__content-main {
    width: 100%;
    max-width: 70rem;
    margin: auto;
  }
  &__desc {
    margin: 0 0 7rem 0;

    &-para {
      font-size: 1.8rem;
      line-height: 1.7;
      color: $colorTerciario;
      margin-bottom: 2rem;
    }
  }
  &__tools-used {
    margin: 0 0 7rem 0;

    &-list {
      display: flex;
      flex-wrap: wrap;
    }
    &-item {
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
      font-size: 1.6rem;
      background: rgba(#999, 0.2);
      border-radius: $commonBorderRadius;
      font-weight: 600;
      color: $colorTerciario;
    }
  }
  &__links {
    margin: 0 0;
    &-btn {
      margin-right: 2rem;
      @include respond(phone) {
        margin-right: 0;
        width: 70%;
        margin-bottom: 2rem;
        text-align: center;
      }
      &:last-child {
        margin: 0;
        @include respond(phone) {
          margin: 0;
        }
      }
    }
  }
}
