.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: $colorPrimario;
  /*  background: linear-gradient(
      to right,
      rgba($colorSecundario, 0.8),
      rgba($colorSecundario, 0.8)
    ); */
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;

    @include respond(tab-port) {
      padding: 0 2rem;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $colorTerciario;
    transition: color .3s;

    &:hover {
      color: $colorSecundario;
    }
  }

  &__logo-img-cont {
    width: 5rem;
    height: 5rem;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 1.5rem;
    background: $colorTerciario;

    @include respond(tab-port) {
      width: 4.5rem;
      height: 4.5rem;
      margin-right: 1.2rem;
    }
  }

  &__logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    transform: scale(1.4, 1.4);
  }

  &__logo-sub {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }

  &__links {
    display: flex;

    @include respond(phone) {
      display: none;
    }
  }

  &__link {
    padding: 1.2rem 2rem;
    display: inline-block;
    font-size: 1.6rem;
    color: $colorTerciario;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    transition: color .3s;

    &:hover {
      color: $colorSecundario;
    }

    @include respond(tab-port) {
      padding: 3rem 1.8rem;
      font-size: 1.5rem;
    }
  }

  &__main-ham-menu-cont {
    display: none;
    width: 3rem;

    padding: 2.2rem 0;

    button {
      background-color: transparent;
      transition: transform 1s;
    }

    .btn-open {
      transform: rotate(90deg);
    }

    @include respond(phone) {
      display: block;
    }
  }

  &__main-ham-menu {
    width: 100%;
  }

  &__main-ham-menu-close {
    width: 100%;
  }

  &__sm-menu {
    background-color: rgba($colorPrimario, 1);

    position: absolute;
    width: 100%;
    top: 100%;

    transition: all .3s;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);


  }

  &__sm-menu-link {
    a {
      display: block;
      text-decoration: none;
      padding: 2.5rem 3rem;
      font-size: 1.6rem;
      color: $colorTerciario;
      text-align: right;
      border-bottom: 1px solid $colorTerciario;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: color .3s;

      &:hover {
        color: $colorSecundario;
      }
    }

    &:first-child {
      a {
        border-top: 1px solid $colorTerciario;
      }
    }
  }

  &__sm-menu-link-last {
    border-bottom: 0;
  }
}