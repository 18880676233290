.main-footer {
  background: #000;
  color: $colorTerciario;

  &__upper {
    display: flex;
    justify-content: space-between;
    padding: 8rem 0;

    @include respond(tab-port) {
      padding: 6rem 0;
    }

    @include respond(phone) {
      display: block;
    }
  }

  &__row-1 {
    order: 2;

    @include respond(tab-port) {
      margin-bottom: 5rem;
    }
  }

  &__row-2 {
    width: 40%;
    order: 1;
    max-width: 50rem;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__short-desc {
    margin-top: 2rem;
    color: white;
    font-size: 1.5rem;
    line-height: 1.7;
  }

  &__social-cont {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;

  }

  &__icon {
    margin-right: 1rem;
    width: 3.2rem;

    &--mr-none {
      margin-right: 0;
    }
  }

  &__lower {
    padding: 4rem 0;
    border-top: 1px solid #444;
    color: $colorTerciario;
    font-size: 1.2rem;
    text-align: left;
    text-align: center;

    a {
      text-decoration: underline;
      font-weight: bold;
      margin-left: 2px;
    }

    @include respond(tab-port) {
      padding: 3.5rem 0;
    }
  }
}